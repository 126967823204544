import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useRecoilCallback } from 'recoil';

import { useRequestLazy } from '~/hooks/use-request-lazy';
import { setJWT } from '~/localstrage/jwt';
import { getSlug } from '~/localstrage/slug';
import { loginWithNewPasswordState } from '~/state/login/atoms';

import { Fields } from './types';

type Parameter = {
  organizationSlug: string;
  username: string;
  password: string;
  practitionerCode: string;
};

type Response = {
  jwt: string;
  deactivated?: 'two_factor';
};

const TokenRequestProps = {
  url: '/practitioner_authentications',
  method: 'POST',
} as const;

export const useSigninPractitionerWithCode = () => {
  const router = useRouter();
  const nextPage = router.query['next'] ? decodeURIComponent(router.query['next'] as string) : null;

  const [isLoading, setIsLoading] = useState(false);

  const [signinAndUpdatePassword, { error }] = useRequestLazy<Parameter, Response>(
    TokenRequestProps,
  );
  const handleSubmit = useRecoilCallback(
    ({ snapshot, reset }) =>
      async (values: Fields) => {
        const state = await snapshot.getPromise(loginWithNewPasswordState);
        const slug = getSlug();
        if (!slug || !state.username || !state.practitionerCode) {
          reset(loginWithNewPasswordState);
          return;
        }

        setIsLoading(true);
        const { data, error: _error } = await signinAndUpdatePassword({
          data: {
            organizationSlug: slug,
            username: state.username,
            password: values.password,
            practitionerCode: state.practitionerCode,
          },
        });

        if (_error || !data) {
          setIsLoading(false);
          return;
        }

        setJWT(data.jwt);

        const page = nextPage || '/';
        window.location.href =
          data.deactivated === 'two_factor' ? `${page}#reset_two_factor` : page;
      },
    [nextPage, signinAndUpdatePassword],
  );

  useEffect(() => {
    return () => {
      setIsLoading(false);
    };
  }, []);

  return {
    error,
    loading: isLoading,
    handleSubmit,
  };
};
