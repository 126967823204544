const KEY = 'slug';

export const setSlug = (slug: string) => {
  window.localStorage.setItem(KEY, slug);
};

export const getSlug = () => {
  return window.localStorage.getItem(KEY);
};

export const clearSlug = () => {
  window.localStorage.removeItem(KEY);
};
