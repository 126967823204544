import { Fields } from './types';

export const useDefaultValues = () => {
  const initialValue: Fields = {
    username: '',
    password: '',
  };

  return initialValue;
};
