import { atom } from 'recoil';

import { LoginState, LoginWithNewPasswordState, LoginWithOnetimeCode } from './types';

export const loginState = atom<LoginState>({
  key: 'login',
  default: {
    organizationName: null,
  },
});

export const loginWithNewPasswordState = atom<LoginWithNewPasswordState>({
  key: 'login.withNewPassoword',
  default: {
    isRequired: false,
    username: null,
    practitionerCode: null,
  },
});

export const loginWithOnetimeCodeState = atom<LoginWithOnetimeCode>({
  key: 'login.withOnetimeCode',
  default: {
    isRequired: false,
    username: null,
    password: null,
  },
});
