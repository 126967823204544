import { object, ObjectSchema, string } from 'yup';

import { FormErrorMessage } from '~/constants/messages';

import { Fields } from './types';

export const validationSchema: ObjectSchema<Fields> = object({
  username: string().label('ユーザーID').required(FormErrorMessage.required),
  password: string().label('パスワード').required(FormErrorMessage.required),
});
