import { useTheme } from '@emotion/react';
import { Formik } from 'formik';
import React, { useEffect, useRef } from 'react';
import { useRecoilCallback, useRecoilValue } from 'recoil';

import {
  Alert,
  Box,
  Button,
  EntryList,
  Flex,
  Header,
  Icon,
  Text,
  TextField,
} from '~/components/blocks';
import { ExternalLink } from '~/components/blocks/ExternalLink';
import { FormErrorMessage } from '~/constants/messages';
import { clearOrganizationName } from '~/localstrage/organization_name';
import { clearSlug } from '~/localstrage/slug';
import { loginState } from '~/state/login/atoms';

import { useSigninPractitioner } from '../use-signin-practitioner';
import { useDefaultValues } from './use-default_values';
import { validationSchema } from './validation';

export const Account = () => {
  const theme = useTheme();
  const inputRef = useRef<HTMLInputElement | null>(null);

  const defaultValues = useDefaultValues();
  const { organizationName } = useRecoilValue(loginState);

  const { error, loading, afterSignup, handleSubmit } = useSigninPractitioner();

  const handleReset = useRecoilCallback(
    ({ reset }) =>
      () => {
        reset(loginState);
        clearOrganizationName();
        clearSlug();
      },
    [],
  );

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.select();
    }
  }, []);

  return (
    <Box>
      <Formik
        initialValues={defaultValues}
        validationSchema={validationSchema}
        onSubmit={(values) => handleSubmit(values)}
      >
        {(formik) => (
          <>
            <Header attached="box">ログイン</Header>
            <Text size="m" mb={theme.space.xl}>
              {organizationName}
            </Text>
            {error && (
              <Alert status="error" mb={theme.space.xl} whiteSpace="pre-wrap">
                {error.responseData && error.responseData['errors']
                  ? ['ログインに失敗しました', error.responseData['errors']].join('\n')
                  : FormErrorMessage.unauthenticated}
              </Alert>
            )}
            <form onSubmit={formik.handleSubmit}>
              <Box>
                <EntryList>
                  <EntryList.Head>ユーザーID</EntryList.Head>
                  <EntryList.Body>
                    <TextField
                      ref={inputRef}
                      error={formik.errors.username}
                      name="username"
                      value={formik.values.username}
                      onChange={formik.handleChange}
                    ></TextField>
                  </EntryList.Body>
                </EntryList>
                <EntryList>
                  <EntryList.Head>パスワード</EntryList.Head>
                  <EntryList.Body>
                    <TextField
                      error={formik.errors.password}
                      type="password"
                      name="password"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                    ></TextField>
                  </EntryList.Body>
                  <Box marginTop={theme.space.l} width="fit-content">
                    <ExternalLink url="https://pharms-cloud.com/help/help_login/">
                      ユーザーID・パスワードを忘れた場合
                    </ExternalLink>
                  </Box>
                </EntryList>
              </Box>
              <Flex
                justifyContent={afterSignup ? 'flex-end' : 'space-between'}
                flexWrap="wrap"
                mt={theme.space.xl}
              >
                {!afterSignup && (
                  <Button use="white" type="button" disabled={loading} onClick={handleReset}>
                    <Icon icon="back-line" size="s" />
                    店舗コード入力
                  </Button>
                )}
                <Button use="base" type="submit" loading={loading}>
                  ログイン
                </Button>
              </Flex>
            </form>
          </>
        )}
      </Formik>
    </Box>
  );
};
