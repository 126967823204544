import axios, { AxiosError } from 'axios';
import applyCaseMiddleware from 'axios-case-converter';

const isAxiosError = (error: Error): error is AxiosError => {
  return error instanceof AxiosError;
};

const isResponseData = (data: unknown): data is { [key: string]: string } =>
  typeof data === 'object';

const axiosInstance = applyCaseMiddleware(
  axios.create({
    baseURL: process.env.apiBaseUrl,
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
    },
  }),
);

export class RequestError extends Error {
  public status: number | undefined;
  public responseData: { [key: string]: string } | undefined;

  constructor(error: AxiosError | Error) {
    super(error.message);

    if (isAxiosError(error)) {
      if (isResponseData(error.response?.data)) {
        this.responseData = error.response?.data;
      }
      this.status = error.response?.status;
    }
  }
}

export const request = axiosInstance.request;
