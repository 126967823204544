import { AxiosRequestConfig } from 'axios';
import { useCallback, useState } from 'react';

import { request as requestAsync, RequestError } from '~/utils/request';

type Params = Required<Pick<AxiosRequestConfig, 'url' | 'method'>> &
  Pick<AxiosRequestConfig, 'headers'>;

// eslint-disable-next-line @typescript-eslint/ban-types
export const useRequestLazy = <P = undefined, R = {}>(params: Params) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<R | null>(null);
  const [error, setError] = useState<RequestError | null>(null);

  const request = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (requestParams: { data?: P; params?: P; url?: string; headers?: any }) => {
      setData(null);
      setError(null);
      setLoading(true);

      const [data, error] = await requestAsync<R>({
        ...params,
        ...requestParams,
      })
        .then((result) => {
          return [result.data, null] as const;
        })
        .catch((error) => {
          return [null, new RequestError(error)] as const;
        });

      setData(data);
      setError(error);
      setLoading(false);

      return {
        loading: false,
        data,
        error,
      };
    },
    [params],
  );

  return [
    request,
    {
      loading,
      data,
      error,
    },
  ] as const;
};
