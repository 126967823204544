import { useTheme } from '@emotion/react';
import { Formik } from 'formik';
import React, { useCallback, useEffect, useRef } from 'react';
import { useRecoilCallback } from 'recoil';

import { Alert, Box, Button, EntryList, Flex, Header, Text, TextField } from '~/components/blocks';
import { ExternalLink } from '~/components/blocks/ExternalLink';
import { useRequestLazy } from '~/hooks/use-request-lazy';
import { setOrganizationName as setOrganizationNameToLocalstorage } from '~/localstrage/organization_name';
import { setSlug } from '~/localstrage/slug';
import { loginState } from '~/state/login/atoms';

import { OrganizationNameRequestProps } from '..';
import { Fields } from './types';
import { validationSchema } from './validation';

const initialValue: Fields = {
  slug: '',
};

export const Organization = () => {
  const theme = useTheme();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const setOrganizationName = useRecoilCallback(({ set }) => (organizationName: string) => {
    set(loginState, { organizationName });
  });
  const [getOrganizationName, { loading, error }] = useRequestLazy<
    { name: string },
    { name: string }
  >(OrganizationNameRequestProps);
  const handleSubmit = useCallback(
    async ({ slug: _slug }: Fields) => {
      // 末尾の半角スペースを取り除く
      const slug = _slug.replace(/ +$/, '');

      const { data, error: _error } = await getOrganizationName({
        url: `${OrganizationNameRequestProps.url}/${slug}`,
      });

      if (_error) return;
      if (!data) return;

      setOrganizationName(data.name);
      setOrganizationNameToLocalstorage(data.name);
      setSlug(slug);
    },
    [getOrganizationName, setOrganizationName],
  );

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.select();
    }
  }, []);

  return (
    <Box>
      <Formik
        initialValues={initialValue}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <>
            <Header attached="box">ログイン</Header>
            <form onSubmit={formik.handleSubmit}>
              <Box>
                {error && (
                  <Alert status="error" mb={theme.space.l}>
                    店舗が見つかりません
                  </Alert>
                )}
                <Text mb={theme.space.xl}>所属している店舗コードを入力してください</Text>
                <EntryList>
                  <EntryList.Head>店舗コード</EntryList.Head>
                  <EntryList.Body>
                    <TextField
                      ref={inputRef}
                      name="slug"
                      value={formik.values.slug}
                      onChange={formik.handleChange}
                      error={formik.errors.slug}
                    ></TextField>
                  </EntryList.Body>
                  <Box marginTop={theme.space.l} width="fit-content">
                    <ExternalLink url="https://pharms-cloud.com/help/help_login/">
                      店舗コードを忘れた場合
                    </ExternalLink>
                  </Box>
                </EntryList>
              </Box>
              <Flex justifyContent="flex-end" mt={theme.space.xl}>
                <Button use="base" type="submit" loading={loading}>
                  次へ
                </Button>
              </Flex>
            </form>
          </>
        )}
      </Formik>
    </Box>
  );
};
