import { object, ObjectSchema, ref, string } from 'yup';

import { FormErrorMessage } from '~/constants/messages';
import { passwordRegexp } from '~/constants/regexp';

import { Fields } from './types';

export const validationSchema: ObjectSchema<Fields> = object({
  password: string()
    .label('パスワード')
    .min(8, FormErrorMessage.minLengthRequiredAlphaSymbol)
    .max(72, FormErrorMessage.maxLengthRequired)
    .matches(passwordRegexp, FormErrorMessage.alphaSymbol)
    .required(FormErrorMessage.required),
  passwordConfirmation: string()
    .label('パスワード（確認）')
    .required(FormErrorMessage.required)
    .oneOf([ref('password')], FormErrorMessage.passwordMismatch),
});
