import { useTheme } from '@emotion/react';
import { Formik, FormikProps } from 'formik';
import React, { useCallback } from 'react';
import { useRecoilValue } from 'recoil';

import { Alert, Button, EntryList, Flex, Header, Text, TextField } from '~/components/blocks';
import { numberRegexp } from '~/constants/regexp';
import { loginWithOnetimeCodeState } from '~/state/login/atoms';
import { toStringHalfNumber } from '~/utils/convert';

import { useSigninPractitioner } from '../use-signin-practitioner';
import { Fields } from './types';
import { useDefaultValues } from './use-default_values';
import { validationSchema } from './validations';

export const TwoFactorAuthentication = () => {
  const theme = useTheme();

  const { loading, error, handleSubmit: signin } = useSigninPractitioner();

  const defaultValues = useDefaultValues();
  const { username, password } = useRecoilValue(loginWithOnetimeCodeState);

  const handleBlur = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, formik: FormikProps<Fields>) => {
      if (!numberRegexp.test(e.currentTarget.value)) return;

      formik.setFieldValue('onetimeCode', toStringHalfNumber(e.currentTarget.value));
    },
    [],
  );
  const handleSubmit = useCallback(
    (values: Fields) => {
      if (!username) return;
      if (!password) return;

      signin(
        {
          username,
          password,
        },
        values.onetimeCode,
      );
    },
    [password, signin, username],
  );

  return (
    <Formik
      enableReinitialize
      initialValues={defaultValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <>
          <Header attached="box">二要素認証</Header>
          {error && (
            <Alert status="error" mb={theme.space.xl} whiteSpace="pre-wrap">
              {error.responseData && error.responseData['errors']
                ? error.responseData['errors']
                : 'エラーが発生しました'}
            </Alert>
          )}
          <form onSubmit={formik.handleSubmit}>
            <Text marginBottom={theme.space.l}>
              お手持ちのスマートフォンの「<strong>Google Authenticator</strong>
              」に表示されている6桁のコードを入力してください
            </Text>
            <EntryList>
              <EntryList.Head>コード</EntryList.Head>
              <EntryList.Body>
                <TextField
                  autoFocus
                  type="text"
                  name="onetimeCode"
                  marginTop={theme.space.l}
                  disabled={loading}
                  error={formik.errors.onetimeCode}
                  value={formik.values.onetimeCode}
                  onBlur={(event) => handleBlur(event, formik)}
                  onChange={formik.handleChange}
                />
              </EntryList.Body>
            </EntryList>
            <Flex justifyContent="flex-end" flexWrap="wrap" marginTop={theme.space.xl}>
              <Button use="base" type="submit" loading={loading}>
                ログイン
              </Button>
            </Flex>
          </form>
        </>
      )}
    </Formik>
  );
};
