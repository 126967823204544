import React from 'react';

import { Setup } from '~/components/layouts';
import { Login } from '~/components/pages/Login';

const LoginPage = () => {
  return (
    <Setup>
      <Login />
    </Setup>
  );
};

export default LoginPage;
