import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useRecoilCallback } from 'recoil';

import { useRequestLazy } from '~/hooks/use-request-lazy';
import { setJWT } from '~/localstrage/jwt';
import { getSlug } from '~/localstrage/slug';
import {
  loginState,
  loginWithNewPasswordState,
  loginWithOnetimeCodeState,
} from '~/state/login/atoms';

import { Fields } from './Account/types';
import { parseNextPage } from './parser';

type Parameter = {
  organizationSlug: string;
  username: string;
  password: string;
  onetimeCode: string | null;
};

const TokenRequestProps = {
  url: '/practitioners',
  method: 'POST',
} as const;

export const useSigninPractitioner = () => {
  const router = useRouter();
  const afterSignup = router.asPath.replace(router.pathname, '') === '#registered';
  const nextPage = router.query['next'] ? parseNextPage(router.query['next'] as string) : null;

  const [isLoading, setIsLoading] = useState(false);

  const [signin, { error }] = useRequestLazy<Parameter, { jwt: string }>(TokenRequestProps);

  const handleSubmit = useRecoilCallback(
    ({ set, reset }) =>
      async (values: Fields, onetimeCode?: string) => {
        const slug = getSlug();
        if (!slug) {
          reset(loginState);
          return;
        }

        setIsLoading(true);
        const { data, error: _error } = await signin({
          data: {
            organizationSlug: slug,
            ...values,
            onetimeCode: onetimeCode || null,
          },
        });

        if (_error?.responseData) {
          if (_error.responseData['required'] === 'set_password') {
            set(loginWithNewPasswordState, {
              isRequired: true,
              username: values.username,
              practitionerCode: values.password,
            });
          }
          if (_error.responseData['required'] === 'onetime_code') {
            set(loginWithOnetimeCodeState, {
              isRequired: true,
              username: values.username,
              password: values.password,
            });
          }
          setIsLoading(false);
          return;
        }
        if (_error || !data) {
          setIsLoading(false);
          return;
        }

        setJWT(data.jwt);
        window.location.href = nextPage || '/';
      },
    [nextPage, signin],
  );

  useEffect(() => {
    return () => {
      setIsLoading(false);
    };
  }, []);

  return {
    error,
    loading: isLoading,
    afterSignup,
    handleSubmit,
  };
};
