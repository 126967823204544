import { useTheme } from '@emotion/react';
import { Formik } from 'formik';
import React, { useEffect, useRef } from 'react';
import { useResetRecoilState } from 'recoil';

import { Alert, Box, Button, EntryList, Flex, Header, Text, TextField } from '~/components/blocks';
import { loginWithNewPasswordState } from '~/state/login/atoms';

import { useDefaultValues } from './use-default_values';
import { useSigninPractitionerWithCode } from './use-signin-practitioner_with_code';
import { validationSchema } from './validation';

export const NewPassword = () => {
  const theme = useTheme();
  const inputRef = useRef<HTMLInputElement | null>(null);

  const defaultValues = useDefaultValues();

  const { loading, error, handleSubmit } = useSigninPractitionerWithCode();

  const resetState = useResetRecoilState(loginWithNewPasswordState);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.select();
    }
  }, []);

  useEffect(() => {
    return () => {
      resetState();
    };
  }, [resetState]);

  return (
    <Box>
      <Formik
        initialValues={defaultValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <>
            <Header attached="box">パスワード設定</Header>
            {error && (
              <Alert status="error" mb={theme.space.xl} whiteSpace="pre-wrap">
                {error.responseData && error.responseData['errors']
                  ? error.responseData['errors']
                  : 'エラーが発生しました'}
              </Alert>
            )}
            <form onSubmit={formik.handleSubmit}>
              <Box>
                <Text mb={theme.space.xl}>
                  仮パスワードでの認証ができました。
                  <br />
                  あらたにパスワードを設定してログインしてください。
                </Text>
                <EntryList>
                  <EntryList.Head>パスワード</EntryList.Head>
                  <EntryList.Body>
                    <TextField
                      ref={inputRef}
                      disabled={loading}
                      name="password"
                      type="password"
                      error={formik.errors.password}
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      placeholder="半角英数記号8文字以上"
                    />
                  </EntryList.Body>
                </EntryList>
                <EntryList>
                  <EntryList.Head>パスワード（確認）</EntryList.Head>
                  <EntryList.Body>
                    <TextField
                      disabled={loading}
                      type="password"
                      name="passwordConfirmation"
                      error={formik.errors.passwordConfirmation}
                      value={formik.values.passwordConfirmation}
                      onChange={formik.handleChange}
                      placeholder="半角英数記号8文字以上"
                    />
                  </EntryList.Body>
                </EntryList>
              </Box>
              <Flex justifyContent="flex-end" flexWrap="wrap" mt={theme.space.xl}>
                <Button use="base" type="submit" loading={loading}>
                  ログイン
                </Button>
              </Flex>
            </form>
          </>
        )}
      </Formik>
    </Box>
  );
};
