import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { Alert, Loader } from '~/components/blocks';
import { useRequestLazy } from '~/hooks/use-request-lazy';
import { clearJWT, getJWT } from '~/localstrage/jwt';
import { clearOrganizationName, setOrganizationName } from '~/localstrage/organization_name';
import { getSlug, setSlug } from '~/localstrage/slug';
import {
  loginState,
  loginWithNewPasswordState,
  loginWithOnetimeCodeState,
} from '~/state/login/atoms';

import { Account } from './Account';
import { NewPassword } from './NewPassword';
import { Organization } from './Organization';
import { parseNextPage } from './parser';
import { TwoFactorAuthentication } from './TwoFactorAuthentication';

export const OrganizationNameRequestProps = {
  url: '/organization_names',
  method: 'GET',
} as const;

export const Login = () => {
  const [isInitialized, setIsInitialized] = useState(false);
  const router = useRouter();
  const [state, setState] = useRecoilState(loginState);
  const newPasswordState = useRecoilValue(loginWithNewPasswordState);
  const onetimeCodeState = useRecoilValue(loginWithOnetimeCodeState);
  const [getOrganizationName] = useRequestLazy<{ name: string }, { name: string }>(
    OrganizationNameRequestProps,
  );
  const slugFromQuery = router.query['id'] as string | undefined;
  const hasNextPage = !!router.query['next'];

  useEffect(() => {
    if (slugFromQuery) {
      // クエリーストリングで指定されたスラグとローカスストレージに保存しているスラグが同じでなければ
      // クエリーストリングで指定されたスラグを優先する
      if (slugFromQuery !== getSlug()) {
        setSlug(slugFromQuery);
        clearJWT(); // ログインセッションを無効にする
        clearOrganizationName();
        router.replace('/login');
      }
    }

    const jwt = getJWT();
    if (jwt) {
      const url = hasNextPage ? parseNextPage(router.query['next'] as string) : '/';
      window.location.href = url;
      return;
    }

    const slug = getSlug();
    if (slug) {
      getOrganizationName({
        url: `${OrganizationNameRequestProps.url}/${slug}`,
      })
        .then(({ data }) => {
          if (data) {
            const organizationName = data.name;
            setState({ organizationName });
            setOrganizationName(organizationName);
          } else {
            // 不正なスラグを指定されたらスラグを入力しなおすため
            setState({ organizationName: null });
          }
        })
        .finally(() => {
          setIsInitialized(true);
        });
    } else {
      setIsInitialized(true);
    }
  }, [getOrganizationName, hasNextPage, router, setState, slugFromQuery]);

  return !isInitialized ? (
    <Loader open inside />
  ) : (
    <>
      {hasNextPage && (
        <Alert status="warning">
          セッションの有効期限が切れました
          <br />
          再度ログインしてください
        </Alert>
      )}
      {state.organizationName ? (
        newPasswordState.isRequired ? (
          <NewPassword />
        ) : onetimeCodeState.isRequired ? (
          <TwoFactorAuthentication />
        ) : (
          <Account />
        )
      ) : (
        <Organization />
      )}
    </>
  );
};
